import { Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Switch } from "@headlessui/react";

import useDarkMode from "use-dark-mode";
import { graphql } from "gatsby";
const navigation = [];

export default function Header() {
  const darkMode = useDarkMode(true, {
    classNameDark: "dark",
    classNameLight: "light",
  });

  return (
    <header>
      <nav className="container" aria-label="Top">
        <div className="flex items-center justify-between w-full py-6 border-b border-white border-opacity-30 lg:border-none">
          <div></div>
          <Link to="/">
            <Logo isDark={darkMode.value} />
          </Link>
          <div className="hidden ml-auto mr-8 space-x-8 lg:block">
            {navigation.map((link) => (
              <Link
                key={link.name}
                to={link.href}
                className="text-base font-medium "
              >
                {link.name}
              </Link>
            ))}
          </div>

          <Switch
            checked={darkMode.value}
            onChange={darkMode.toggle}
            className={`${
              darkMode.value
                ? "bg-gray-100 border-white"
                : "bg-gray-900 border-gray-200"
            } border relative inline-flex items-center h-6 rounded-full w-11 ml-4`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                darkMode.value
                  ? "translate-x-6 bg-black"
                  : "translate-x-1 bg-white"
              } inline-block w-4 h-4 transform duration-300  rounded-full`}
            />
          </Switch>
        </div>
        <div className="flex flex-wrap justify-center py-4 lg:hidden">
          {navigation.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="mr-4 text-base font-medium "
            >
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}

export function Logo({ isDark }) {
  const [isSSR, setIsSSR] = useState(typeof window == "undefined");
  const { config } = useStaticQuery(graphql`
    {
      config: prismicConfig {
        data {
          logo_dark {
            fluid(maxWidth: 120) {
              src
            }
          }
          logo_white {
            fluid(maxWidth: 120) {
              src
            }
          }
        }
      }
    }
  `);
  useLayoutEffect(() => {
    setIsSSR(typeof window == "undefined");
  }, []);
  if (isSSR) {
    return null;
  } else {
    return isDark ? (
      <img src={config?.data?.logo_white?.fluid?.src} />
    ) : (
      <img src={config?.data?.logo_dark?.fluid?.src} />
    );
  }
}
