import React from "react";
import Helmet from "react-helmet";
import get from "lodash.get";
import { graphql, StaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

export default function Seo({ data }) {
  const { pathname, def } = useLocation();
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
          def: prismicHomepage {
            seo: data {
              meta_title
              meta_description
              meta_image {
                url
              }
            }
          }
        }
      `}
      render={({ site }) => {
        const url = `${get(site, "siteMetadata.siteUrl", "")}${pathname}`;
        const title = get(data, "meta_title") || def?.meta_title;
        const description =
          get(data, "meta_description") || def?.meta_description;
        const keywords = get(data, "meta_keywords");
        const image = get(data, "meta_image.url") || def?.meta_image?.url;
        const type = "website";
        return (
          <>
            <Helmet>
              <html lang={"de"}></html>
              {title && <title>{title}</title>}
              {title && <meta property="og:title" content={title} />}
              {title && <meta name="twitter:title" content={title} />}
              <meta name="og:type" content={type} />
              <meta name="og:url" content={url} />
              {description && <meta name="description" content={description} />}
              {description && (
                <meta property="og:description" content={description} />
              )}
              {description && (
                <meta name="twitter:description" content={description} />
              )}
              {image && <meta name="image" content={image} />}
              {image && <meta property="og:image" content={image} />}
              {image && <meta name="twitter:image" content={image} />}
              {keywords && <meta name="keywords" content={keywords} />}
              {keywords && <meta property="og:keywords" content={keywords} />}
              {keywords && <meta name="twitter:keywords" content={keywords} />}
              <meta name="twitter:card" content="summary_large_image" />{" "}
            </Helmet>
          </>
        );
      }}
    />
  );
}
