export default function linkResolver(doc) {
  switch (doc.type) {
    case "page":
      return "/" + doc.uid;
    case "location":
      return "/" + "location/" + doc.uid;

    default:
      return "/";
  }
}
