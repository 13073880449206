import React from "react";
import BerryHeader from "./BerryHeader";
import Footer from "./Footer";

export default function BerrysLayout({ children }) {
  return (
    <>
      {" "}
      <BerryHeader />
      <main className="relative flex-grow">{children}</main>
      <Footer />
    </>
  );
}
